<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="objectratings2175"
		tooltipMsg="manualforobject3313"
		addNewRecordMsg
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OreviewCreatedDatetime', ascending: 0 }"
		modelId="OreviewId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="ObjectReviewsTable"
	>
		<template #customEditActions>
			<megau-button
				:disabled="isButtonDisabledForActivations"
				classprop="btn btn-secondary mr-1"
				icon="edit"
				:title="$t('activateunconfi')"
				@handleClick="setActiveForListPage(confirmActionSetActiveForListPage)"
			></megau-button>
		</template>

		<template #ObjectId="list">O{{ list.row.ObjectId }}</template>

		<template #ObjectName="list">
			<a :href="list.row.ObjectUrl" target="_blank">{{ list.row.ObjectName }}</a>
		</template>

		<template #Rating="list">
			<div class="d-flex">
				{{ (+list.row.Rating).toFixed(1) }}
				<note-display
					v-if="list.row.OreviewPositives !== '' || list.row.OreviewNegatives === ''"
					:value="formatReviews(list.row.OreviewPositives, list.row.OreviewNegatives)"
					class="ml-2"
				/>
			</div>
		</template>

		<template #OreviewCreatedDatetime="list">{{ list.row.OreviewCreatedDatetime | dateTime }}</template>

		<template #OreviewActivationDatetime="list">{{ list.row.OreviewActivationDatetime | dateTime }}</template>

		<template #OreviewFullName="list">{{ list.row.OreviewFirstname }} {{ list.row.OreviewLastname }}</template>

		<template #OreviewReservationId="list">
			<div class="d-flex flex-row align-items-center justify-content-end">
				<megau-button
					classprop="btn btn-info mr-1 ml-1"
					v-show="list.row.OreviewReservationId"
					icon="calendar-alt"
					:tooltip="`${$t('pairedreservati')} ${list.row.OreviewReservationId}`"
					@handleClick="redirectToReservation(list.row.OreviewReservationId)"
				></megau-button>
			</div>
		</template>

		<template #OreviewChecked="list">
			<boolean-display :boolValue="list.row.OreviewChecked"></boolean-display>
		</template>

		<template #OreviewIsActive="list">
			<div class="d-flex flex-row align-items-center justify-content-end">
				<a
					href="#"
					@click.prevent="openActiveModal(list.row.OreviewId, list.row.OreviewIsActive)"
				>{{ list.row.OreviewIsActive ? $t('deactivate') : $t('activate') }}</a>
				<note-display :value="list.row.OreviewAdminNote" v-if="list.row.OreviewAdminNote" class="ml-2" />
			</div>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import NoteDisplay from '@/components/common/note-display';
import BooleanDisplay from '@/components/common/boolean-display';

import api from './object-review.api';
import service from './object-review.service.js';
import http from '../../services/helpers/http';
import { tableColumns } from './object-review.table-data';
import serviceCommon from '../../services/service/common.service';

export default {
	name: 'ObjectReviewTable',

	components: {
		TablePage,
		NoteDisplay,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectReviewListItem',
			isButtonDisabledForActivations: true,
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	async mounted() {
		this.onFilterChanged();
	},

	methods: {
		redirectToDetail(OreviewId = 0) {
			this.$router.push({
				name: links.objectReviewsDetailName,
				params: { OreviewId },
			});
		},

		onFilterChanged() {
			this.isButtonDisabledForActivations = !serviceCommon.hasCustomFilter(this.$options.name);
		},

		async prepareColumns(columns) {
			/*
			const [territoryEnum, districtEnum] = await Promise.all([
				serviceDictionary.getTerritoryEnum(),
				serviceDictionary.getDistrictEnum(),
			]);
			*/

			let cols = prepareOreviewLanguage.bind(this)(columns);

			return cols;

			function prepareOreviewLanguage(columns) {
				const values = this.$store.getters.countryLanguageCodeForDropdown;

				return setDropdownOptions(columns, 'OreviewLanguage', values);
			}
		},

		redirectToReservation(objectReservationId) {
			this.$router.push({
				name: links.objectReservationDetailName,
				params: { objectReservationId },
			});
		},

		formatReviews(positiveReview, negativeReview) {
			return `<b>${this.$i18n.t('prosoftheaccomm3111')}</b> ${positiveReview}\n<b>${this.$i18n.t(
				'consoftheaccomm3112'
			)}</b> ${negativeReview}`;
		},

		openActiveModal(itemId, isActive) {
			service.openChangeActiveModal(this.$modal, this.changeActive, itemId, isActive);
		},
		changeActive(itemId) {
			api.changeActive(itemId).then(() => this.tableGetData());
		},

		setActiveForListPage(callbackFnc) {
			this.$modal.show({
				title: this.$i18n.t('bulkactivationo'),
				text: this.$i18n.t('doyouwanttocoll'),
				okTitle: this.$i18n.t('ok2344'),
				cancelTitle: this.$i18n.t('cancel2343'),
				modalClass: 'modal-danger',
				variant: 'danger',
				onConfirm() {
					return callbackFnc();
				},
			});
		},

		async confirmActionSetActiveForListPage() {
			const pagination = serviceCommon.getTablePagination(this.$options.name);

			const data = {
				...pagination,
				filter: serviceCommon.getCustomFilter(this.$options.name),
				getCount: false,
			};

			this.startLoading(false);
			const response = await http.getWithFilters('ObjectReviewListItem/ChangeActiveList', data);
			this.stopLoading();
			if (response) {
				this.tableGetData();
			}
		},
	},
};
</script>
<style lang="scss">
.ObjectReviewsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(2) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(3) {
		min-width: 50px;
		width: 50px;
	}
}
</style>