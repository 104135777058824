export const tableColumns = [
	{
		model: 'OreviewLanguage',
		i18n: 'language',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'ObjectId',
		i18n: 'oid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ObjectName',
		i18n: 'object',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OreviewCreatedDatetime',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'OreviewActivationDatetime',
		i18n: 'activated2178',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'OreviewFullName',
		i18n: 'name',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OreviewEmail',
		i18n: 'e-mail',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'Rating',
		i18n: 'rating',
		sortable: true,
		filter: 'numberrange',
		filterOptions: {
			props: {
				min: 0,
				max: 10,
				step: 0.1,
			},
		},
	},
	{
		model: 'OreviewReservationId',
		i18n: 'reservation',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'OreviewChecked',
		i18n: 'checked',
		sortable: true,
		filter: 'boolean',
	},
	{
		colType: 'country',
		model: 'ObjectCountryId',
		i18n: 'country',
		hidden: true,
		sortable: true,
	},
	{
		model: 'OreviewIsActive',
		i18n: 'active',
		sortable: true,
		filter: 'boolean',
	},
];
