var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "ObjectReviewsTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "objectratings2175",
          tooltipMsg: "manualforobject3313",
          addNewRecordMsg: "",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "OreviewCreatedDatetime", ascending: 0 },
          modelId: "OreviewId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "customEditActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      disabled: _vm.isButtonDisabledForActivations,
                      classprop: "btn btn-secondary mr-1",
                      icon: "edit",
                      title: _vm.$t("activateunconfi"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.setActiveForListPage(
                          _vm.confirmActionSetActiveForListPage
                        )
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "ObjectId",
              fn: function (list) {
                return [_vm._v("O" + _vm._s(list.row.ObjectId))]
              },
            },
            {
              key: "ObjectName",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    { attrs: { href: list.row.ObjectUrl, target: "_blank" } },
                    [_vm._v(_vm._s(list.row.ObjectName))]
                  ),
                ]
              },
            },
            {
              key: "Rating",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s((+list.row.Rating).toFixed(1)) +
                          "\n\t\t\t"
                      ),
                      list.row.OreviewPositives !== "" ||
                      list.row.OreviewNegatives === ""
                        ? _c("note-display", {
                            staticClass: "ml-2",
                            attrs: {
                              value: _vm.formatReviews(
                                list.row.OreviewPositives,
                                list.row.OreviewNegatives
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "OreviewCreatedDatetime",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("dateTime")(list.row.OreviewCreatedDatetime))
                  ),
                ]
              },
            },
            {
              key: "OreviewActivationDatetime",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateTime")(list.row.OreviewActivationDatetime)
                    )
                  ),
                ]
              },
            },
            {
              key: "OreviewFullName",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(list.row.OreviewFirstname) +
                      " " +
                      _vm._s(list.row.OreviewLastname)
                  ),
                ]
              },
            },
            {
              key: "OreviewReservationId",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-end",
                    },
                    [
                      _c("megau-button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: list.row.OreviewReservationId,
                            expression: "list.row.OreviewReservationId",
                          },
                        ],
                        attrs: {
                          classprop: "btn btn-info mr-1 ml-1",
                          icon: "calendar-alt",
                          tooltip:
                            _vm.$t("pairedreservati") +
                            " " +
                            list.row.OreviewReservationId,
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.redirectToReservation(
                              list.row.OreviewReservationId
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "OreviewChecked",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: list.row.OreviewChecked },
                  }),
                ]
              },
            },
            {
              key: "OreviewIsActive",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-end",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openActiveModal(
                                list.row.OreviewId,
                                list.row.OreviewIsActive
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              list.row.OreviewIsActive
                                ? _vm.$t("deactivate")
                                : _vm.$t("activate")
                            )
                          ),
                        ]
                      ),
                      list.row.OreviewAdminNote
                        ? _c("note-display", {
                            staticClass: "ml-2",
                            attrs: { value: list.row.OreviewAdminNote },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          889120464
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }